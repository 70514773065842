import CryptoJS from "crypto-js";

// import config file
import configFile from "../../config.json";

export function IsLogin(navigate) {
    if (localStorage.getItem('lh_api_access_token') !== null) {
        fetch(configFile["api_url"] + "/users/verify-user", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            }
        })
            .then(response => response.json())
            .then(response => {
                // if user's token expire, redirect to user login page. Else, do nothing
                if (response["detail"] === "Could not validate credentials") {
                    navigate("/user-login", { replace: true });
                    clearLocalStorage();
                }
            })
            .catch(error => console.error(error));
    }
    else
        // prevent user from accessing other sites if not logged in
        navigate("/user-login", { replace: true });
}

export function IsAccountActive(navigate) {
    if (localStorage.getItem('lh_api_access_token') !== null) {
        navigate("/", { replace: true });
    }
}

export function encryptText(textVal) {
    let iv = CryptoJS.lib.WordArray.random(16);
    let encryptedData = CryptoJS.AES.encrypt(textVal, CryptoJS.enc.Base64.parse("582fd0bf686c7520fc4d0c7d94298cce"), { iv: iv });
    let joinedData = iv.clone().concat(encryptedData.ciphertext);
    return CryptoJS.enc.Base64.stringify(joinedData);
}

export function clearLocalStorage() {
    localStorage.removeItem('lh_api_access_token');
    localStorage.removeItem('lh_api_display_name');
}