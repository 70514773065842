// import header and footer
import Navbar from './pages/header_footer/Navbar';
import Footer from './pages/header_footer/Footer';

// import main pages
import Home from "./pages/main_pages/Home";
import Tool from "./pages/main_pages/Tools";
import Login from "./pages/main_pages/Login";
import Abouts from "./pages/main_pages/About";
import SignUp from "./pages/main_pages/SignUp";
import Warning from "./pages/main_pages/Warning";
import LHtables from "./pages/main_pages/LHtables";
import Profile from "./pages/main_pages/UserProfile";

// import tools page
import ImageConverter from "./pages/tools/ImageConverter";

import { Route, Routes, useLocation } from "react-router-dom";

// import config file
import configFile from "./config.json";

function App() {
    const loadingScreen = document.getElementById("loadingScreen");
    const location = useLocation();
    if (loadingScreen) {
        fetch(configFile["api_status_url"] + "/testapi")
            .then(response => response.json())
            .then(response => {
                if (response["Success"] === "PC is online.") {
                    loadingScreen.style.marginTop = "-1000vh";
                    loadingScreen.style.overflow = "hidden";
                }
            })
            .catch();
    }
    return (
        <div className='mainContainer'>
            <Navbar />
            <div className="container">
                <Routes location={location} key={location.key}>
                    <Route path="/" element={<Home />} />
                    <Route path="/user-login" element={<Login />} />
                    <Route path="/user-profile" element={<Profile />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/lhtables" element={<LHtables />} />
                    <Route path="/about" element={<Abouts />} />
                    <Route path="/tools" element={<Tool />} />
                    <Route path="/tools/imageconverter" element={<ImageConverter />} />
                    <Route path="*" element={<Warning />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
