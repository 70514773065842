import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';
import { IsLogin } from '../functions/LoginCheck';
import { useEffect, useState, useRef } from 'react';

import { IoTrashOutline } from 'react-icons/io5';
import { AiOutlineUpload, AiOutlineSend } from 'react-icons/ai';

// import config file
import configFile from '../../config.json';

export default function ImageConverter() {
    const inputFile = useRef(null);
    const navigate = useNavigate();
    const fileTypeLst = ["PNG", "JPEG", "GIF", "BMP", "TIFF", "WEBP", "ICO", "SVG", "PDF"]
    const [imageList, setImageList] = useState([]);
    const [imageHTMLList, setImageHTMLList] = useState([]);
    const [ddlSelectImageTypeMain, setddlSelectImageTypeMain] = useState("JPEG");
    const [triggerImageUpload, setTriggerImageUpload] = useState(false);
    const [triggerImageConvert, setTriggerImageConvert] = useState(false);
    const [isDownloadAll, setIsDownloadAll] = useState(false);

    useEffect(() => {
        IsLogin(navigate);
        window.scrollTo(0, 0);
    }, [navigate])
    useEffect(() => {
        if (triggerImageUpload)
        {
            let imgHtmlLst = [];
            for (let i in imageList) {
                let state = "Uploaded";
                if (imageList[i][1] !== "")
                    state = "Download";
                imgHtmlLst.push(
                    <div className="ImageConverter-UploadedImgContainer" key={"imgLst" + i}>
                        <div className="ImageConverter-UploadedImgContainerSub">
                            <div className="ImageConverter-UploadedImgName">{imageList[i][0]["name"]}</div>
                            <div className="ImageConverter-UploadedConvertType">
                                <div className="ImageConverter-UploadedMsg">to</div>
                                <select disabled={state !== "Uploaded"} className="ImageConverter-UploadedConvertLst" 
                                    value={imageList[i][2]} onChange={e => {
                                        let temp_imageLst = [...imageList];
                                        temp_imageLst[i][2] = e.target.value;
                                        setddlSelectImageTypeMain(0);
                                        setImageList(temp_imageLst);
                                        setTriggerImageUpload(true);
                                    }}>
                                    {ImageTypeListValue()}
                                </select>
                            </div>
                        </div>
                        <div className="ImageConverter-UploadedImgContainerSub">
                            {state === "Uploaded" ?
                                <div className="ImageConverter-UploadedImgStatus">{state}</div>
                                :
                                <a href={`data:image/${imageList[i][2].toLowerCase()};base64,${imageList[i][1]}`}
                                    download={imageList[i][0]["name"].split(".")[0]+"."+imageList[i][2].toLowerCase()}
                                    className="ImageConverter-UploadedImgStatus">
                                    {state}
                                </a>
                            }
                            <button className="ImageConverter-UploadedImgEdit"
                                onClick={() => {
                                    let temp_imageLst = [...imageList];
                                    temp_imageLst.splice(i, 1);
                                    setTriggerImageUpload(true);
                                    setImageList(temp_imageLst);
                                }}>
                                <IoTrashOutline/>
                            </button>
                        </div>
                    </div>
                );
            }
            setImageHTMLList(imgHtmlLst);
            setTriggerImageUpload(false);
        }
    }, [imageList, triggerImageUpload])
    useEffect(() => { 
        let temp_allDownload = true;
        for (let imgLst of imageList)
        {
            if (imgLst[1] === "")
            {
                temp_allDownload = false;
                break;
            }
        }
        setIsDownloadAll(temp_allDownload);
    }, [imageList])
    useEffect(() => {
        if(triggerImageConvert)
        {
            for (let imgIndex in imageList)
            {
                if (imageList[imgIndex][1] === "")
                {
                    let formData = new FormData();
                    formData.append("image", imageList[imgIndex][0]);
                    let queryUrl = configFile["api_url"] + "/userDataManager/convertImageType?imageType=";
                    queryUrl += imageList[imgIndex][2];
                    fetch(queryUrl, {
                        method: "POST",
                        headers: {
                            // "Content-Type": file.type,
                            "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
                        },
                        body: formData
                    })
                    .then(response => response.json())
                    .then(response => {
                        if ("Success" in response)
                        {
                            let temp_image = [...imageList];
                            temp_image[imgIndex][1] = response["Success"];
                            setImageList(temp_image);
                            setTriggerImageUpload(true);
                        }
                    })
                    .catch(error => console.error(error));
                }
            }
            setTriggerImageConvert(false);
        }
    }, [triggerImageConvert])

    function ImageTypeListValue() {
        let imageTypeLst = []
        for (let i of fileTypeLst)
        {
            imageTypeLst.push(<option key={"Options"+i} value={i}>{i}</option>);
        }
        return imageTypeLst;
    }

    const handleDrop = (event) => {
        event.preventDefault();
        let imgLst = [...imageList];
        for (let i of event.dataTransfer.files) {
            // "" = uploaded, notEmpty = converted
            imgLst.push([i, "", "JPEG"]);
        }
        setImageList(imgLst);
        setTriggerImageUpload(true);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };
    return (
        <div className='ImageConverter'>
            <div className='ImageConverterContainer'>
                <div className="ImageConverter-MainContainer">
                    <div className="ImageConverter-TitleContainer">
                        <div className="ImageConverter-Title">Image Converter</div>
                    </div>
                    <div className="ImageConverter-ConverterContainer">
                        <div className="ImageConverter-InputContainer">
                            {/* <div className="ImageConverter-InputOutputTitle">Input Image(s)</div> */}
                            <div className="ImageConverter-ImageDragDropBox"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}>
                                <div className="ImageConverter-DragDropContainer">
                                    <div className="ImageConverter-UploadIcon">
                                        <div className="ImageConverter-UploadIconWrap">
                                            <AiOutlineUpload />
                                        </div>
                                    </div>
                                    <div>Drag and drop images here</div>
                                </div>
                                <label htmlFor="filesInput" className="ImageConverter-SelectImage">Choose Files</label>
                                <input type="file" multiple id="filesInput" ref={inputFile}
                                    accept="image/*"
                                    onChange={e => {
                                        e.preventDefault();
                                        let imgLst = [...imageList];
                                        for (let i of e.target.files) {
                                            // "" = uploaded, notEmpty = converted
                                            imgLst.push([i, "", "JPEG"]);
                                        }
                                        setImageList(imgLst);
                                        setTriggerImageUpload(true);
                                    }}
                                    className="TablePage-TableImageUpload2" />
                            </div>
                        </div>
                        {imageList.length > 0 ?
                            <div className='ImageConverter-OutputContainer'>
                                <div className='ImageConverter-OutputTitleContainer'>
                                    <div className="ImageConverter-InputOutputTitle">Uploaded Image(s)</div>
                                    <div className="ImageConverter-ConvertContainer">
                                        <div className="ImageConverter-MainSelectImgTypeConvertContainer">
                                            <div className="ImageConverter-MainSelectImgTypeMSG">all to</div>
                                            <select className="ImageConverter-UploadedConvertLst" 
                                                disabled={isDownloadAll}
                                                value={ddlSelectImageTypeMain} onChange={e => {
                                                    let temp_imageLst = [...imageList];
                                                    for (let i in temp_imageLst)
                                                    {
                                                        if (temp_imageLst[i][1] === "")
                                                            temp_imageLst[i][2] = e.target.value;
                                                    }
                                                    setTriggerImageUpload(true);
                                                    setImageList(temp_imageLst);
                                                    setddlSelectImageTypeMain(e.target.value);
                                                }}>
                                                <option key={"OptionsNone"} value={0}>...</option>
                                                {ImageTypeListValue()}
                                            </select>
                                        </div>
                                        {isDownloadAll ?
                                            <button className="ImageConverter-ConvertAllBtn"
                                                onClick={async e => {
                                                    e.preventDefault();
                                                    const zip = new JSZip();
                                                    for (let imgdl of imageList)
                                                    {
                                                        // const link = document.createElement('a');
                                                        // link.href = `data:image/${imgdl[2].toLowerCase()};base64,${imgdl[1]}`;
                                                        // link.download = imgdl[0]["name"].split(".")[0]+"."+imgdl[2].toLowerCase();
                                                        // link.rel='noopener noreferrer'
                                                        // link.click();
                                                        zip.file(imgdl[0]["name"].split(".")[0]+"."+imgdl[2].toLowerCase(), imgdl[1], {base64: true})
                                                    }
                                                    const zipped = await zip.generateAsync({
                                                        type: 'blob',
                                                    })
                                                    const link = document.createElement('a');
                                                    link.href = URL.createObjectURL(zipped);
                                                    link.download = "images.zip";
                                                    link.rel='noopener noreferrer'
                                                    link.click();
                                                }}>
                                                Download All
                                            </button>
                                            :
                                            <button className="ImageConverter-ConvertAllBtn"
                                                onClick={() => {
                                                        setTriggerImageConvert(true);
                                                }}>
                                                Convert All <AiOutlineSend/>
                                            </button>
                                        }
                                    </div>
                                </div>
                                {/* <img src={image} alt="Dropped" style={{ width: '100%', height: '100%' }} /> */}
                                {imageHTMLList}
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}