// import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom';
// import { useState, useEffect, useRef } from 'react';
import { useState, useEffect } from 'react';
import { DefaultText, PasswordText } from '../functions/TextModule'
import { IsAccountActive, encryptText } from '../functions/LoginCheck';

// import config file
import configFile from "../../config.json";

export default function Login() {
    // Initialise
    const navigate = useNavigate();
    const [emptyUID, setEmptyUID] = useState(false);
    const [emptyPWD, setEmptyPWD] = useState(false);
    const [showpwd, setShowpwd] = useState(true);
    const [showInvalid, setShowInvalid] = useState(false);

    // Prevent logged in user from accessing
    useEffect(() => {
        IsAccountActive(navigate)
        window.scrollTo(0, 0);
    }, [navigate])

    // Enter key for logging in
    const handleKeyDown = (event) => {
        if (event.key === "Enter")
            ValidateLogin(setEmptyUID, setEmptyPWD, navigate, setShowInvalid)
    }

    // Validate login field entry
    function ValidateLogin() {
        let uid = document.getElementById("usernametxt").value.trim();
        let pwd = document.getElementById("passwordtxt").value;
        setEmptyUID(uid === "");
        setEmptyPWD(pwd === "");
        if (uid !== "" && pwd !== "")
            queryLogin(uid, pwd);
    }

    // Query to login user
    function queryLogin(uid, pwd) {
        fetch(configFile["api_url"] + "/users/sign-in", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "username": uid,
                "password": encryptText(pwd)
            })
        })
            .then(response => response.json())
            .then(response => {
                // If user do not exist, show error
                if (response["Error"] !== undefined)
                    setShowInvalid(true);
                // If user exist, login user and store token and display name
                else if (response["access-token"] !== undefined) {
                    localStorage.setItem('lh_api_access_token', response["access-token"]);
                    if (response["displayName"] !== undefined)
                        localStorage.setItem('lh_api_display_name', response["displayName"]);
                    navigate('/', { replace: true });
                }
            })
            .catch(error => console.error(error));
    };

    return (
        <div className="LoginPage">
            <div className='LoginContainer3'>
                <img src='./img/system/sorinosk1-min.svg' alt="Logo" className="loginLogo" />
                <div className='vLine' />
            </div>
            <div className="LoginContainer1">
                {showInvalid && (<div className='InvalidTxt'>Invalid User</div>)}
                <DefaultText
                    name="Username"
                    inputID="usernametxt"
                    toggleErrorTxt={emptyUID}
                    task={handleKeyDown}
                />
                <PasswordText
                    name="Password"
                    inputID="passwordtxt"
                    toggleErrorTxt={emptyPWD}
                    task={handleKeyDown}
                    showElement={showpwd}
                    setShowElement={setShowpwd}
                />
                <div className='LoginContainer2'>
                    <button className="loginSubmit" onClick={() => ValidateLogin()}>Login</button>
                </div>
                <div className='LoginContainer2'>
                    <div>
                        <Link to="/signup" className="loginCreateUser" id="loginCreateUser">Sign Up</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}