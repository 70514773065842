import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IsAccountActive, encryptText } from '../functions/LoginCheck';
import { DefaultText, DefaultTextCustomMSG, PasswordText, PasswordTextCustomMSG } from '../functions/TextModule';

// import config file
import configFile from "../../config.json";

export default function Login(){
    const navigate = useNavigate();
    const [emptyUID, setEmptyUID] = useState(false);
    const [emptyPWD, setEmptyPWD] = useState(false);
    const [emptyCfmPWD, setEmptyCfmPWD] = useState(false);
    const [pwdMessage, setPwdMessage] = useState("(Cannot be empty)");
    const [uidMessage, setUidMessage] = useState("(Cannot be empty)");
    const [showPwd, setShowPwd] = useState(true);
    const [showCfmPwd, setShowCfmPwd] = useState(true);

    // Prevent logged in user from accessing sign up page
    useEffect(() => {
        IsAccountActive(navigate)
        window.scrollTo(0, 0)
    }, [navigate])

    // Enter key to create user
    const handleKeyDown = (event) => {
        if (event.key === "Enter")
            ValidateCreateUser(setEmptyUID, setEmptyPWD, setEmptyCfmPWD, setPwdMessage, setUidMessage, navigate)
    }

    return (
        <div className="LoginPage">
            <div className='LoginContainer3'>
                <img src='./img/system/sorinosk1.svg' alt='Logo' className="loginLogo"/>
                <div className='vLine'/>
            </div>
            <div className="LoginContainer1">
                <DefaultText
                    name="Display Name"
                    inputID="dpnametxt"
                    toggleErrorTxt={false}
                    task={handleKeyDown}
                />
                <DefaultTextCustomMSG
                    name="Username"
                    inputID="usernametxt"
                    toggleErrorTxt={emptyUID}
                    task={handleKeyDown}
                    message={uidMessage}
                />
                <PasswordTextCustomMSG
                    name="Password"
                    inputID="passwordtxt"
                    toggleErrorTxt={emptyPWD}
                    task={handleKeyDown}
                    showElement={showPwd}
                    setShowElement={setShowPwd}
                    message={pwdMessage}
                />
                <PasswordText
                    name="Confirm Password"
                    inputID="cfmpasswordtxt"
                    toggleErrorTxt={emptyCfmPWD}
                    task={handleKeyDown}
                    showElement={showCfmPwd}
                    setShowElement={setShowCfmPwd}
                />
                <div className='LoginContainer2'>
                    <button className="loginSubmit" onClick={() => ValidateCreateUser(setEmptyUID, setEmptyPWD, setEmptyCfmPWD, setPwdMessage, setUidMessage, navigate)}>Create User</button>
                </div>
                <div className='LoginContainer2'>
                    <div>
                        <Link to="/user-login" className="loginCreateUser" id="loginCreateUser">Cancel</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Validate create user field
function ValidateCreateUser(setEmptyUID, setEmptyPWD, setEmptyCfmPWD, setPwdMessage, setUidMessage, navigate)
{
    let dp = document.getElementById("dpnametxt").value;
    let uid = document.getElementById("usernametxt").value;
    let pwd = document.getElementById("passwordtxt").value;
    let cfmpwd = document.getElementById("cfmpasswordtxt").value;
    let valid = true;
    setPwdMessage("(Cannot be empty)");
    setUidMessage("(Cannot be empty)");
    if (pwd !== cfmpwd)
        valid = false;
    setEmptyUID(uid === "");
    setEmptyPWD(pwd === "");
    setEmptyCfmPWD(cfmpwd === "");
    if (!valid)
    {
        setEmptyPWD(true);
        setPwdMessage("(Confirm password do not match)");
        if (pwd.length < 8)
            setPwdMessage("(Must be at least 8 character)");
    }
    if (uid !== "" && pwd !== "" && valid)
        queryUserFunction(uid, pwd, dp, navigate, setEmptyUID, setUidMessage);
}

// Query to create user
function queryUserFunction(uid, pwd, dp, navigate, setEmptyUID, setUidMessage)
{
    fetch(configFile["api_url"] + "/users/sign-up", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "displayname": dp,
            "username": uid,
            "password": encryptText(pwd)
        })
    })
    .then(response => response.json())
    .then(response => {
        // Login user if created successfully
        if (response["Success"] !== undefined)
        {
            localStorage.setItem('lh_api_access_token', response["access-token"]);
            queryLogin(uid, pwd, navigate)
        }
        // Error will be given if username is taken
        else if (response["detail"] !== undefined)
        {
            if (response["detail"]["Error message"]["errno"]===1062)
            {
                setEmptyUID(true);
                setUidMessage("(Username taken)");
            }
        }
    })
    .catch(error => console.error(error));
};

// Query to login user
function queryLogin(uid, pwd, navigate)
{
    fetch(configFile["api_url"] + "/users/sign-in", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "username": uid,
            "password": encryptText(pwd)
        })
    })
    .then(response => response.json())
    .then(response => {
        // If user exist, login user and store token and display name
        if (response["access-token"] !== undefined)
        {
            localStorage.setItem('lh_api_access_token', response["access-token"]);
            if (response["displayName"] !== undefined)
                localStorage.setItem('lh_api_display_name', response["displayName"]);
            navigate('/', { replace: true });
        }
    })
    .catch(error => console.error(error));
};