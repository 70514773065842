import { IsLogin } from '../functions/LoginCheck';
import { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IoAddCircleOutline, IoCheckmarkCircleOutline, IoBanOutline, IoTrashOutline, IoDocumentTextOutline, IoSearchOutline, IoCreateOutline, IoCloseSharp, IoCaretDownOutline, IoImagesOutline } from 'react-icons/io5';

import { clearLocalStorage } from '../functions/LoginCheck';
// import logging setup
import Loadings from '../functions/Loading';

//import config file
import configFile from '../../config.json';

export default function Home() {
    const navigate = useNavigate();
    // For modification of table details
    const CharToPrevent = "\"";
    const inputFile = useRef(null);
    const [loadState, setLoadState] = useState(true);
    const [loading, setLoading] = useState({});
    // For filtering data
    const [searchData, setSearchData] = useState("");
    const [ddlSelectVal, setddlSelectVal] = useState(0);
    // For determining state
    const [isEditTable, setEditTable] = useState(false);                // Stores the state if user is trying to edit the table.
    const [isAddingData, setAddingData] = useState(true);
    const [isEditData, setEditData] = useState({});
    const [isDelete, setDelete] = useState({});
    // To determine state of expansion for each data
    const [isDataExtended, setDataExtended] = useState({});
    // Store all images
    const [Image, setImage] = useState({});
    // For adding data
    const [newData, setNewData] = useState({});
    // For table and datapoint
    const [tableDetails, setTableDetails] = useState({});               // Stores the full information of a table retrieved from api
    const [DataList, setDataList] = useState([]);
    const [modifiedTableDetails, setModifiedTableDetails] = useState({});
    const [modifiedDataPoint, setModifiedDataPoint] = useState({});
    // For retrieving information of selected table from url
    const [searchParams, setSearchParams] = useSearchParams();          // Used to retrieve data from URL

    // Run on initial load
    useEffect(() => {
        IsLogin(navigate);
        queryTableDetails();
        window.scrollTo(0, 0)
    }, [navigate])

    useEffect(() => {
        setLoadState(!Object.values(loading).every(item => item === false))
    }, [loading])

    // An API call to retrieve information of selected table
    function queryTableDetails() {
        setLoading({ ...loading, "queryTableDetails": true });
        fetch(configFile["api_url"] + "/userDataManager/getTable", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableID": searchParams.get("tableid"),
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if (response["Error"] !== undefined) {
                        console.log(response["Error"]);
                        navigate("/", { replace: true });
                    }
                    else {
                        setTableDetails(response);
                        setModifiedTableDetails(response["columns"]);
                        queryTableImage();
                        queryDataList();
                    }
                }
                setLoading({ ...loading, "queryTableDetails": false });
            })
            .catch(error => console.error(error));
    }
    // An API to update table information
    function queryUpdateTable() {
        setLoading({ ...loading, "queryUpdateTable": true });
        fetch(configFile["api_url"] + "/userDataManager/updateTable", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableID": tableDetails.id,
                "tableName": tableDetails["name"].replaceAll("'", "\\'"),
                "tableSummary": tableDetails["summary"].replaceAll("'", "\\'"),
                "tableColumns": JSON.stringify(modifiedTableDetails).replaceAll("'", "\\'")
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"]);
                    else {
                        queryTableDetails();
                        queryUpdateTableImage();
                    }
                }
                setLoading({ ...loading, "queryUpdateTable": false });
            })
            .catch(error => console.error(error));
    }

    function queryTableImage() {
        setLoading({ ...loading, "queryTableImage": true });
        let queryUrl = configFile["api_url"] + "/userDataManager/getTableImage?tableID=";
        queryUrl += searchParams.get("tableid");
        fetch(queryUrl, {
            method: "GET",
            headers: {
                // "Content-Type": file.type,
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if ("Success" in response)
                        setImage({ ...Image, "TableImage": response["Success"] })
                }
                setLoading({ ...loading, "queryTableImage": false });
            })
            .catch(error => console.error(error));
    }

    function queryUpdateTableImage() {
        let formData = new FormData();
        if (Image["UpdateTableImage"]) {
            setLoading({ ...loading, "queryUpdateTableImage": true });
            formData.append("image", Image["UpdateTableImage"]);
            let queryUrl = configFile["api_url"] + "/userDataManager/updateTableImage?tableID=";
            queryUrl += searchParams.get("tableid");
            fetch(queryUrl, {
                method: "POST",
                headers: {
                    // "Content-Type": file.type,
                    "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
                },
                body: formData
            })
                .then(response => response.json())
                .then(response => {
                    if (response !== null) {
                        // Store list if there is no error
                        if ("detail" in response) {
                            console.log(response["Error"]);
                            if (response["detail"] === "Could not validate credentials") {
                                navigate("/user-login", { replace: true });
                                clearLocalStorage();
                            }
                        }
                        else if ("Errors" in response)
                            console.log(response)
                        else {
                            Reflect.deleteProperty(Image, "UpdateTableImage")
                            queryTableImage()
                        }
                    }
                    setLoading({ ...loading, "queryUpdateTableImage": false });
                })
                .catch(error => console.error(error));
        }
    }

    // Query API for table list to be stored in DataList
    function queryDataList() {
        setLoading({ ...loading, "queryDataList": true });
        fetch(configFile["api_url"] + "/userDataManager/listTableData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableID": searchParams.get("tableid").toString()
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else {
                        response = response.sort((a, b) => {
                            if (a["data"][ddlSelectVal] < b["data"][ddlSelectVal])
                                return -1;
                            else
                                return 0;
                        });
                        setDataList(response);
                    }
                    setLoading({ ...loading, "queryDataList": false });
                }
            })
            .catch();
    }

    // Query API to insert a new table
    function queryAddDataPoint() {
        setLoading({ ...loading, "queryAddDataPoint": true });
        fetch(configFile["api_url"] + "/userDataManager/addTableData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableID": searchParams.get("tableid").toString(),
                "tableData": JSON.stringify(newData).replaceAll("'", "\\'")
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"])
                    else {
                        queryDataList();
                        setAddingData(!isAddingData)
                    }
                }
                setLoading({ ...loading, "queryAddDataPoint": false });
            })
            .catch(error => console.error(error));
    }

    // Query API to update a data point
    function queryUpdateDataPoint(id) {
        setLoading({ ...loading, "queryUpdateDataPoint": true });
        fetch(configFile["api_url"] + "/userDataManager/updateTableData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "dataID": id.toString(),
                "tableData": JSON.stringify(modifiedDataPoint[id]).replaceAll("'", "\\'")
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"]);
                    else {
                        Reflect.deleteProperty(modifiedDataPoint, id);
                        queryDataList();
                    }
                }
                setLoading({ ...loading, "queryUpdateDataPoint": false });
            })
            .catch(error => console.error(error));
    }

    function queryDataImage(id) {
        setLoading({ ...loading, "queryDataImage": true });
        let queryUrl = configFile["api_url"] + "/userDataManager/getDataImage?tableID=";
        queryUrl += searchParams.get("tableid");
        queryUrl += "&dataID=";
        queryUrl += id;
        fetch(queryUrl, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if ("Success" in response)
                        setImage({ ...Image, [id]: response["Success"] })
                }
                setLoading({ ...loading, "queryDataImage": false });
            })
            .catch(error => console.error(error));
    }

    function queryUpdateDataImage(id) {
        setLoading({ ...loading, "queryUpdateDataImage": true });
        let formData = new FormData();
        if (Image["UpdateImageID" + id]) {
            formData.append("image", Image["UpdateImageID" + id]);
            let queryUrl = configFile["api_url"] + "/userDataManager/updateDataImage?tableID=";
            queryUrl += searchParams.get("tableid");
            queryUrl += "&dataID=";
            queryUrl += id;
            fetch(queryUrl, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
                },
                body: formData
            })
                .then(response => response.json())
                .then(response => {
                    if (response !== null) {
                        // Store list if there is no error
                        if ("detail" in response) {
                            console.log(response["Error"]);
                            if (response["detail"] === "Could not validate credentials") {
                                navigate("/user-login", { replace: true });
                                clearLocalStorage();
                            }
                        }
                        else if ("Errors" in response)
                            console.log(response)
                        else
                            Reflect.deleteProperty(Image, "UpdateImageID" + id)
                    }
                    setLoading({ ...loading, "queryUpdateDataImage": false });
                })
                .catch(error => console.error(error));
        }
    }

    // Query API to remove a data point
    function queryRemoveDataPoint(id) {
        setLoading({ ...loading, "queryRemoveDataPoint": true });
        fetch(configFile["api_url"] + "/userDataManager/removeTableData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "dataID": id.toString(),
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"]);
                    else {
                        queryDataList();
                        Reflect.deleteProperty(isDataExtended, id);
                    }
                }
                setLoading({ ...loading, "queryRemoveDataPoint": false });
            })
            .catch(error => console.error(error));
    }

    return (
        <div className="TablePage">
            {loadState ? <Loadings /> : ""}
            <div className="TablePage-MainContainer">
                <div className="TablePage-TitleContainer2">
                    <div className="TablePage-TitleContainer">
                        {/* IMAGE AND TITLE IS TOGETHER */}
                        <div className="TablePage-TableImageContainer">
                            <img className="TablePage-TableImage" width="11rem" height="auto" alt=""
                                src={Image["UpdateTableImage"] !== undefined && Image["UpdateTableImage"] !== "" ?
                                    URL.createObjectURL(Image["UpdateTableImage"]) : Image["TableImage"] === undefined || Image["TableImage"] === "" ?
                                        './img/system/noimage.svg' : `data:image/jpeg;base64,${Image["TableImage"]}`} onClick={() => {
                                            if (Image["TableImage"] !== undefined && Image["TableImage"] !== "") {
                                                const link = document.createElement('a');
                                                link.href = `data:image/jpeg;base64,${Image["TableImage"]}`;
                                                link.download = tableDetails.name;
                                                link.rel = 'noopener noreferrer'
                                                link.click();
                                            }
                                        }} />
                            {
                                isEditTable ?
                                    <div className="TablePage-TableImageUpload">
                                        <label htmlFor="tableFileInput" className="TablePage-TableImageUpload1"><IoImagesOutline /></label>
                                        <input type="file" id="tableFileInput" ref={inputFile}
                                            accept="image/jpeg, image/jpg"
                                            onChange={e => {
                                                let fileName = String(e.target.files[0].name)
                                                if (fileName.slice(-3) === "jpg" || fileName.slice(-4) === "jpeg")
                                                    setImage({ ...Image, "UpdateTableImage": e.target.files[0] })
                                                else
                                                    console.log("Invalid file type, ignoring file.");
                                                e.target.value = "";
                                            }}
                                            className="TablePage-TableImageUpload2" />
                                    </div>
                                    : ""
                            }
                        </div>
                        <div className="TablePage-DescptContainer">
                            <div className="TablePage-TableNameContainer">
                                {
                                    isEditTable && tableDetails.columns !== undefined ?
                                        <input className="TablePage-EditTableNameInput"
                                            defaultValue={"name" in tableDetails ? tableDetails.name : "Table Name"}
                                            onChange={e => setModifiedTableDetails({ ...modifiedTableDetails, 0: e.target.value })} />
                                        :
                                        <div className="TablePage-TableNametxt">
                                            {"name" in tableDetails ? tableDetails.name : "Table Name"}
                                        </div>
                                }
                                <div className="TablePage-EditTableBtnContainer">
                                    <button className="TablePage-EditTableBtn"
                                        onClick={() => {
                                            if (isEditTable)
                                                queryUpdateTable();
                                            setEditTable(!isEditTable);
                                        }}>
                                        {isEditTable ? <IoDocumentTextOutline /> : <IoCreateOutline />}
                                    </button>
                                    {isEditTable ?
                                        <button className="TablePage-EditTableBtn"
                                            onClick={() => {
                                                setEditTable(!isEditTable);
                                                queryTableDetails();
                                            }}>
                                            <IoBanOutline />
                                        </button>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                            <div className="TablePage-TableDescptContainer">
                                <div className="TablePage-TableDescptlbl" >Description:</div>
                                {isEditTable && tableDetails.columns !== undefined ?
                                    <textarea className="TablePage-TableDescptInput"
                                        defaultValue={tableDetails.summary}
                                        onChange={e => setModifiedTableDetails({ ...modifiedTableDetails, 1: e.target.value })} />
                                    :
                                    <div className="TablePage-TableDescpt" >{tableDetails.summary}</div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* COLUMNS WILL ONLY BE SHOWN IF USER IS EDITING TABLE CONTENT */}
                    {isEditTable ?
                        <div className="TablePage-ColumnsContainer">
                            <button className="TablePage-AddColumnsBtn"
                                onClick={() => {
                                    // Add new columns
                                    let newIndex = Math.max(...Object.keys(tableDetails.columns).map(key => { return parseInt(key) })) + 1;
                                    setTableDetails({ ...tableDetails, "columns": { ...tableDetails["columns"], [newIndex]: "" } });
                                }}>
                                <IoAddCircleOutline /> Add
                            </button>
                            <div className="TablePage-TableColumns">
                                {
                                    // Display all columns available to the table
                                    Object.entries(tableDetails["columns"]).map(([key, value]) => {
                                        if (value === "name" || value === "detail" || value === "image")
                                            return (<label key={"columnRestricted" + key} id={key} className="TablePage-Columnslbl">{value}</label>);
                                        else {
                                            return (
                                                <div key={"columnsEditor" + key} className="TablePage-addColumnsContainer">
                                                    <input id={"columns" + key} className="TablePage-ColumnsInput" defaultValue={value} maxLength={15}
                                                        onChange={e => {
                                                            // setColumnModified([key, e.target.value]);
                                                            setModifiedTableDetails({ ...modifiedTableDetails, [key]: e.target.value })
                                                        }} />
                                                    <button className="TablePage-removeColumnsBtn" key={key + "colbtn"}
                                                        onClick={() => {
                                                            let col = tableDetails["columns"]
                                                            Reflect.deleteProperty(col, key)
                                                            Reflect.deleteProperty(modifiedTableDetails, key)
                                                            setTableDetails({ ...tableDetails, "columns": col });
                                                        }}><IoCloseSharp /></button>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        : ""}
                </div>
                {/* DATA LIST CONTAINER STARTS FROM HERE */}
                <div className="TablePage-DataListMainContainer">
                    <div className="HomePage-AccessibilityContainer">
                        <div className="HomePage-AccessibilityContainer1">
                            <div className="HomePage-SearchBarContainer">
                                <input className="HomePage-SearchBar"
                                    id="searchInput"
                                    value={searchData}
                                    onChange={e => {
                                        setSearchData(e.target.value);
                                    }} />
                                <div className="HomePage-SearchIcon"><IoSearchOutline /></div>
                                <div className="HomePage-Search-CancelButton"
                                    onClick={() => setSearchData("")}>
                                    <IoCloseSharp />
                                </div>
                            </div>
                            <select className="TablePage-DropDownList" value={ddlSelectVal}
                                onChange={e => {
                                    setddlSelectVal(e.target.value);
                                    let DataSort = DataList.sort((a, b) => {
                                        if (a["data"][e.target.value] < b["data"][e.target.value])
                                            return -1;
                                        else
                                            return 0;
                                    });
                                    setDataList(DataSort);
                                }}>
                                {
                                    // Set drop down menu for user to sort with data type
                                    tableDetails["columns"] !== undefined ?
                                        Object.entries(tableDetails["columns"]).map(([key, values]) => {
                                            if (values !== "image" && values !== "detail")
                                                return (
                                                    <option key={"Options" + key} value={key}>{values}</option>
                                                );
                                            else
                                                return (<></>);
                                        }) : <></>
                                }
                            </select>
                        </div>
                        <div className='HomePage-MainButtonContainer'>
                            <button className='HomePage-MainButton1' onClick={() => {
                                setAddingData(!isAddingData)
                            }}>
                                {
                                    isAddingData ?
                                        <IoAddCircleOutline />
                                        :
                                        <IoBanOutline />
                                }
                            </button>
                        </div>
                    </div>
                    {/* DATA LIST STARTS FROM HERE */}
                    <div className='TablePage-DataListContainer'>
                        {
                            // THIS SECTION IS ONLY FOR ADDING OF DATA
                            !isAddingData ?
                                <div key="CreateDataPointContainer" className="TablePage-CreateDataPointMainContainer">
                                    <div className="TablePage-DataPointContainer">
                                        <img className="TablePage-DataPointImage" alt="NoImage" src='./img/system/noimage.svg' />
                                        <div className="TablePage-DataPointNameContainer1">
                                            <div className="TablePage-DataPointNameContainer2">
                                                <div className="TablePage-DataPointNameInput">
                                                    <div className="TablePage-DataPointNamelbl">Name:</div>
                                                    <input id="addDataPointName" className="TablePage-addDataPointNameInput" maxLength={100}
                                                        onKeyDown={e => {
                                                            if (e.key === CharToPrevent)
                                                                e.preventDefault();
                                                        }}
                                                        onChange={e => {
                                                            setNewData({ ...newData, 0: e.target.value });
                                                        }} />
                                                </div>
                                                <div className='TablePage-DataGrpBtnContainer'>
                                                    <button className="HomePage-AddDataGrpBtn"
                                                        style={{ backgroundColor: 'rgba(129, 219, 166, 0.432)' }} onClick={() => {
                                                            queryAddDataPoint();
                                                        }}>
                                                        <IoCheckmarkCircleOutline />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="TablePage-DataPointDescptContainer">
                                                <div className="TablePage-DataPointNamelbl">Description:</div>
                                                <textarea id="addDataPointDesc" className="TablePage-DataPointDescptInput"
                                                    onKeyDown={e => {
                                                        if (e.key === CharToPrevent)
                                                            e.preventDefault();
                                                    }}
                                                    onChange={e => {
                                                        setNewData({ ...newData, 1: e.target.value });
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TablePage-TableDataMainContainer">
                                        {
                                            tableDetails.hasOwnProperty("columns") ?
                                                Object.entries(tableDetails.columns).map(([key, column]) => {
                                                    if (key !== "0" && key !== "1" && key !== "2") {
                                                        return (
                                                            <div key={"columnDataContainer" + key} className="TablePage-DataContainer">
                                                                <div className="TablePage-DataNamelbl">{column + ":"}</div>
                                                                <input className="TablePage-DataInput"
                                                                    maxLength={30}
                                                                    onKeyDown={e => {
                                                                        if (e.key === CharToPrevent)
                                                                            e.preventDefault();
                                                                    }}
                                                                    onChange={e => {
                                                                        setNewData({ ...newData, [key]: e.target.value });
                                                                    }} />
                                                            </div>
                                                        );
                                                    }
                                                    else {
                                                        return (<></>);
                                                    }
                                                }) : <></>
                                        }
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            // THIS SECTION IS FOR LOADING ALL DATA POINTS
                            DataList.length === 0 ?
                                <div key={0} className="HomePage-DataGrpContainer-IndividualTable">
                                    <label className="TablePage-DataPointNameDisplay">You do not have any data group!</label>
                                </div> :
                                DataList.map((data, index) => {
                                    if (Object.values(DataList[index]["data"]).join(" ").toUpperCase().includes(searchData.toUpperCase())) {
                                        if (!isDataExtended[data.id]) {
                                            // IF NOT EXTENDED
                                            return (
                                                <div className="HomePage-DataGrpContainer-IndividualTable" key={data.id + "lst"} id={data.id}
                                                    onClick={() => {
                                                        // IF A DATAPOINT IS EXTENDED, SET THAT IT IS EXTENDED, AND GET IT'S IMAGE
                                                        setDataExtended({ ...isDataExtended, [data.id]: !isDataExtended[data.id] })
                                                        queryDataImage(data.id);
                                                    }}>
                                                    <label className="TablePage-DataPointNameSN">{index}</label>
                                                    <label className="TablePage-DataPointNameDisplay">
                                                        {data.data["0"]}
                                                    </label>
                                                </div>
                                            )
                                        }
                                        else {
                                            // IF EXTENDED
                                            return (
                                                <div className="TablePage-DataMainContainer-Individual" key={data.id + "lst"}>
                                                    <label className="TablePage-Data-ArrowSymb"
                                                        onClick={() => {
                                                            // IF CLOSE, UNSET EXTENDED, DELETE ANY IMAGES
                                                            setDataExtended({ ...isDataExtended, [data.id]: !isDataExtended[data.id] })
                                                            Reflect.deleteProperty(Image, data.id)
                                                        }}>
                                                        <IoCaretDownOutline />
                                                    </label>
                                                    <div className="TablePage-DataPointMainContainer">
                                                        <div className="TablePage-DataPointContainer">
                                                            <div className="TablePage-DataImageContainer">
                                                                <div>
                                                                    <img className="TablePage-DataPointImage"
                                                                        alt="" id={"TablePage-DataPointImage-" + data.id}
                                                                        src={Image[data.id] !== undefined && Image[data.id] !== "" ?
                                                                            `data:image/jpeg;base64,${Image[data.id]}` : './img/system/noimage.svg'} onClick={() => {
                                                                                if (Image[data.id] !== undefined && Image[data.id] !== "") {
                                                                                    const link = document.createElement('a');
                                                                                    link.href = `data:image/jpeg;base64,${Image[data.id]}`;
                                                                                    link.download = data["data"]["0"];
                                                                                    link.rel = 'noopener noreferrer'
                                                                                    link.click();
                                                                                }
                                                                            }} />
                                                                </div>
                                                                {isEditData[data.id] ?
                                                                    <div className="TablePage-DataImageUpload">
                                                                        <label htmlFor="dataFileInput" className="TablePage-DataImageUpload1"><IoImagesOutline /></label>
                                                                        <input type="file" id="dataFileInput" ref={inputFile}
                                                                            accept="image/jpeg, image/jpg"
                                                                            onChange={e => {
                                                                                let fileName = String(e.target.files[0].name)
                                                                                if (fileName.slice(-3) === "jpg" || fileName.slice(-4) === "jpeg") {
                                                                                    // IF UPLOADED NEW IMAGE, STORE NEW IMAGE AND CHANGE SRC OF IMAGE
                                                                                    let imgEle = document.getElementById("TablePage-DataPointImage-" + data.id);
                                                                                    imgEle.src = URL.createObjectURL(e.target.files[0]);
                                                                                    setImage({ ...Image, ["UpdateImageID" + data.id]: e.target.files[0] });
                                                                                }
                                                                                else
                                                                                    console.log("Invalid file type, ignoring file.");
                                                                                e.target.value = "";
                                                                            }}
                                                                            className="TablePage-TableImageUpload2" />
                                                                    </div>
                                                                    : ""
                                                                }
                                                            </div>
                                                            <div className="TablePage-DataPointNameContainer1">
                                                                <div className="TablePage-DataPointNameContainer2">
                                                                    <div className={isEditData[data.id] ? "TablePage-DataPointNameInput" : "TabePage-DataPointNameContainer3"}>
                                                                        <div className="TablePage-DataPointNamelbl">Name:</div>
                                                                        {isEditData[data.id] ?
                                                                            <input className="TablePage-addDataPointNameInput"
                                                                                defaultValue={data.data["0"]}
                                                                                maxLength={100}
                                                                                onKeyDown={e => {
                                                                                    if (e.key === CharToPrevent)
                                                                                        e.preventDefault();
                                                                                    else if (e.key === "Enter") {
                                                                                        // ENTER TO STORE DATA
                                                                                        queryUpdateDataPoint(data.id);
                                                                                        queryUpdateDataImage(data.id);
                                                                                        setEditData(true);
                                                                                    }
                                                                                }}
                                                                                onChange={e => {
                                                                                    setModifiedDataPoint({ ...modifiedDataPoint, [data.id]: { ...modifiedDataPoint[data.id], "0": e.target.value } })
                                                                                }} />
                                                                            :
                                                                            <label className="TablePage-DataPointName">{data.data["0"]}</label>
                                                                        }
                                                                    </div>
                                                                    <div className='TablePage-DataGrpBtnContainer'>
                                                                        <button className="HomePage-AddDataGrpBtn"
                                                                            style={{ backgroundColor: isEditData[data.id] ? "rgba(129, 219, 166, 0.432)" : "" }}
                                                                            onClick={() => {
                                                                                if (isEditData[data.id]) {
                                                                                    // IF IT IS IN EDIT MODE, UPDATE DATA
                                                                                    queryUpdateDataPoint(data.id);
                                                                                    queryUpdateDataImage(data.id);
                                                                                }
                                                                                else {
                                                                                    // ELSE GET ALL DATA AND STORE INTO A TEMPORARY OBJECT
                                                                                    setModifiedDataPoint({ ...modifiedDataPoint, [data.id]: data.data })
                                                                                }
                                                                                setEditData({ ...isEditData, [data.id]: !isEditData[data.id] });
                                                                            }}>
                                                                            {isEditData[data.id] ? <IoDocumentTextOutline /> : <IoCreateOutline />}
                                                                        </button>
                                                                        <button className="HomePage-AddDataGrpBtn"
                                                                            style={{ backgroundColor: isEditData[data.id] ? "" : "rgba(236, 157, 157, 0.432)" }}
                                                                            onClick={() => {
                                                                                if (!isEditData[data.id]) {
                                                                                    // IF IT IS NOT IN EDIT MODE, DELETE DATA
                                                                                    setDelete({ ...isDelete, [data.id]: true });
                                                                                    // queryRemoveDataPoint(data.id);
                                                                                }
                                                                                else {
                                                                                    // IF IT IS NOT IN EDIT MODE, IT IS IN CANCEL MODE
                                                                                    // RESET IMAGE, AND REMOVE TEMPORARY OBJECT AND IMAGES
                                                                                    let imgEle = document.getElementById("TablePage-DataPointImage-" + data.id);
                                                                                    imgEle.src = Image[data.id] !== undefined && Image[data.id] !== "" ? `data:image/jpeg;base64,${Image[data.id]}` : './img/system/noimage.svg'
                                                                                    Reflect.deleteProperty(modifiedDataPoint, data.id)
                                                                                    Reflect.deleteProperty(Image, "UpdateImageID" + data.id)
                                                                                    setEditData({ ...isEditData, [data.id]: !isEditData[data.id] });
                                                                                }
                                                                            }}>
                                                                            {isEditData[data.id] ? <IoBanOutline /> : <IoTrashOutline />}
                                                                        </button>
                                                                        {
                                                                            isDelete[data.id] && (
                                                                                <div className="DeletePrompt-MainContainer">
                                                                                    <div className="DeletePrompt-Container">
                                                                                        <p className="DeletePrompt-Text">Are you sure you want to delete datapoint "{data.data[0]}"?</p>
                                                                                        <div className="DeletePrompt-ButtonContainer">
                                                                                            <button className="DeletePrompt-Button" onClick={() => {
                                                                                                queryRemoveDataPoint(data.id);
                                                                                            }}>Yes</button>
                                                                                            <button className="DeletePrompt-Button" onClick={() => {
                                                                                                setDelete({ ...isDelete, [data.id]: false })
                                                                                            }}>No</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="TablePage-DataPointDescptContainer">
                                                                    <div className="TablePage-DataPointDescptlbl">Description:</div>
                                                                    {isEditData[data.id] ?
                                                                        <textarea defaultValue={data.data["1"]}
                                                                            className="TablePage-DataPointDescptInput"
                                                                            onKeyDown={e => {
                                                                                if (e.key === CharToPrevent)
                                                                                    e.preventDefault();
                                                                                // else if (e.key === "Enter") {
                                                                                //     // ENTER TO STORE DATA
                                                                                //     queryUpdateDataPoint(data.id);
                                                                                //     queryUpdateDataImage(data.id);
                                                                                //     setEditData(true);
                                                                                // }
                                                                            }}
                                                                            onChange={e => {
                                                                                setModifiedDataPoint({ ...modifiedDataPoint, [data.id]: { ...modifiedDataPoint[data.id], "1": e.target.value } })
                                                                            }} />
                                                                        :
                                                                        <label className="TablePage-DataPointDescpt">{data.data["1"]}</label>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="TablePage-DataPointColumnsContainer">
                                                            {
                                                                Object.entries(tableDetails.columns).map(([key, column]) => {
                                                                    if (key !== "0" && key !== "1" && key !== "2") {
                                                                        return (
                                                                            <div key={"columnDataContainer" + key} className="TablePage-DataContainer">
                                                                                <div className="TablePage-DataNamelbl">{column + ":"}</div>
                                                                                {
                                                                                    isEditData[data.id] ?
                                                                                        <input className="TablePage-DataInput"
                                                                                            defaultValue={data.data[key]}
                                                                                            maxLength={30}
                                                                                            onKeyDown={e => {
                                                                                                if (e.key === CharToPrevent)
                                                                                                    e.preventDefault();
                                                                                                else if (e.key === "Enter") {
                                                                                                    // ENTER TO STORE DATA
                                                                                                    queryUpdateDataPoint(data.id);
                                                                                                    queryUpdateDataImage(data.id);
                                                                                                    setEditData(true);
                                                                                                }
                                                                                            }}
                                                                                            onChange={e => {
                                                                                                setModifiedDataPoint({ ...modifiedDataPoint, [data.id]: { ...modifiedDataPoint[data.id], [key]: e.target.value } })
                                                                                            }} />
                                                                                        :
                                                                                        <label className="TablePage-DataDisplay" >{data.data[key]}</label>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else return (<></>)
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                    else return (<></>)
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}