import { Link, useMatch, useResolvedPath, useNavigate, useLocation } from "react-router-dom"
import { BsTools, BsFillGeoAltFill } from "react-icons/bs"
import { ImUser } from "react-icons/im"
import { GiExitDoor } from "react-icons/gi"
import { useState, useEffect } from "react";
import { clearLocalStorage } from '../functions/LoginCheck';

export default function Navbar()
{
    const location = useLocation();
    const navigate = useNavigate();
    const [navOpen, setNavOpen] = useState(false);
    const [validUser, setValidUser] = useState(false);
    useEffect(() => {
        resizeEvent();
    },[]);
    useEffect(() => {
        resizeEvent();
    });
    useEffect(() => {
        if (location.pathname !== "\\user-login" && location.pathname !== "\\signup")
        {
            if (localStorage.getItem("lh_api_access_token") !== "")
            {
                setValidUser(true);
            }
            else
            {
                setValidUser(false);
            }
        }
        else
        {
            setValidUser(false);
        }
    },[location])
    return (
    <nav className = "nav">
        <div className="nav-container">
            <Link to="/" className = "site-title" onClick={() => setNavOpen(false)}>
                <div className="site-title-container">
                    <div id="site-title-initial" className = "site-title-initial">Limitless</div>
                    <div id="site-title-back" className = "site-title-back">Horizon</div>
                </div>
            </Link>
            <div className="toggle-button" onClick={() => setNavOpen(!navOpen)}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </div>
        <div className = "expanded" style={{
            top: navOpen ? "0" : "-500%",
            transitionDelay: navOpen ? "0s": "0s",
        }}>
            <ul>
                <CustomLink to="/about" onClick={() => setNavOpen(false)}>
                    <div className="navLinks">
                        <BsFillGeoAltFill/>
                        <span>About</span>
                    </div>
                </CustomLink>
                {validUser&&(
                    <CustomLink to="/tools" onClick={() => setNavOpen(false)}>
                        <div className="navLinks">
                            <BsTools/>
                            <span>Tools</span>
                        </div>
                    </CustomLink>
                )}
                {validUser&&(
                    <CustomLink to="/user-profile" onClick={() => {setNavOpen(false)}}>
                        <div className="navLinks">
                            <ImUser/>
                            <span>Profile</span>
                        </div>
                    </CustomLink>
                )}
                {validUser&&(
                    <CustomLink to="/user-login" onClick={() => {
                        setNavOpen(false);
                        clearLocalStorage();
                        navigate("/user-login", { replace: true });
                        }}>
                        <div className="navLinks">
                            <GiExitDoor/>
                            <span>Logout</span>
                        </div>
                    </CustomLink>
                )}
            </ul>
        </div>
    </nav>
    );
}

function CustomLink({to, children, ...props})
{
    //const path = window.location.pathname;
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch( {path: resolvedPath.pathname, end: true})
    return (
        <li className={isActive ? "active" : ""}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}

function resizeEvent() 
{
    let winWidth = window.innerWidth;
    let txt2 = document.getElementById("site-title-back");
    if (winWidth <= 420)
        txt2.innerHTML = "H";
    else
        txt2.innerHTML = "Horizon";
}
window.addEventListener("resize", resizeEvent)