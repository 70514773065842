import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BsTools } from 'react-icons/bs';
import { AiOutlineFileImage } from 'react-icons/ai'
import { IoCloseSharp, IoSearchOutline } from 'react-icons/io5';
import { IsLogin } from '../functions/LoginCheck';

export default function Tool(){
    const navigate = useNavigate();             
    const [searchData, setSearchData] = useState([]);

    // Run on initial load
    useEffect(() => {
        IsLogin(navigate);                                              // Check for Login
        window.scrollTo(0, 0)
    }, [navigate])

    return (
        <div className="HomePage">
            <div className="HomePage-MainContainer">
                <div className="ToolPage-TitleContainer">
                    <div className="ToolPage-Title">Tool applications <BsTools/></div>
                </div>
                <div className="HomePage-TableListMainContainer">
                    <div className="ToolPage-AccessibilityContainer">
                        <div className="ToolPage-SearchBarContainer">
                            <input className="ToolPage-SearchBar" 
                                value={searchData} 
                                id="searchInput" 
                                onChange={e=> {
                                    setSearchData(e.target.value);
                                }}/>
                            <div className="HomePage-SearchIcon"><IoSearchOutline/></div>
                            <div className="ToolPage-Search-CancelButton"
                                onClick={() => setSearchData("")}>
                                    <IoCloseSharp/>
                            </div>
                        </div>
                        <div className='HomePage-MainButtonContainer'>
                        </div>
                    </div>
                    <div className='ToolPage-ToolListContainer'>
                        <SiteCell 
                            srvImg={<AiOutlineFileImage/>}
                            title="Image converter" 
                            buttonInfo="Go To App"
                            buttonInactivity= {false}
                            secondButton= {true}
                            accessSite = "./imageconverter"
                            text1="" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function SiteCell({srvImg, title, text1, text2, buttonInfo, buttonInactivity, secondButton, secondButtonInfo, accessSite, accessSite2, delay, opacityV, ...props})
{
    return (
        <div className="ToolContainerPadding">
            <div className="ToolContainer"> 
                <div className="ToolInfoImg">{srvImg}</div>
                <div className="ToolContainer1">
                    <div className="ToolInfoTitle">
                        {title}
                    </div>
                    <div className="ToolInfoContainer">
                        <div className="ToolInfoContainer2">
                            {buttonInactivity ? 
                                <a className="ToolInfoButtonIA">
                                    {buttonInfo}
                                </a>
                                :
                                <Link className="ToolInfoButton" to = {accessSite} {...props}>{buttonInfo}</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}