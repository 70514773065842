import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// import functions styles
import "./styles/functions/styles.css";
import "./styles/functions/fontStyles.css";
import "./styles/functions/deletePrompt.css";
import "./styles/functions/loadingStyles.css";

// import header footer styles
import "./styles/header_footer/navStyles.css";

// import main_pages styles
import "./styles/main_pages/homeStyles.css";
import "./styles/main_pages/toolStyles.css";
import "./styles/main_pages/aboutStyles.css";
import "./styles/main_pages/loginStyles.css";
import "./styles/main_pages/lhtableStyles.css";
import "./styles/main_pages/warningStyles.css";
import "./styles/main_pages/userprofileStyles.css";

//import tools styles
import "./styles/tools/imageConverterStyles.css";

import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
