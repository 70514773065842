import { useEffect } from 'react';

export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="AboutPage">
            <div className="interestContainer">
                <div className='interestImg'>
                    <img className="intImg"  alt="" src={'./img/system/sorinosk1.svg'} />
                </div>
                <div className='interestMsg'>
                    <div className='interestMsgTitle'>
                        About This Site
                    </div>
                    <div className='interestMsgText'>
                        <p>Hellos, I hope that you have also found this website to be useful. This website is privately owned and was created with the intention to assist with daily tasks.</p>
                        <p>Since I developed and managed the website alone, I sincerely apologize if it ever produces bugs. Slowness on some functions is to be expected because I have set the server to operate in power saving mode when idle.</p>
                        <p>Any bugs or new suggestion can be <a href="mailto: s.sinkiat@outlook.sg" target='noreferrer'>mailed</a> to me.</p>
                    </div>
                </div>
            </div>
            <div className="aLine" />
        </div>
    );
}
