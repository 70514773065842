import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IsLogin } from '../functions/LoginCheck';
import { IoAddCircleOutline, IoPencilSharp, IoCloseSharp, IoBanOutline, IoTrashOutline, IoDocumentTextOutline, IoSearchOutline } from 'react-icons/io5';

import { clearLocalStorage } from '../functions/LoginCheck';
// import logging setup
import Loadings from '../functions/Loading';

// import config file
import configFile from "../../config.json";

export default function Home() {
    const navigate = useNavigate();
    const [loadState, setLoadState] = useState(true);                   // True False to display loading
    const [loading, setLoading] = useState({});                         // List of True/False for each API call
    const [tableList, setTableList] = useState([]);                     // Stores a list of tables a user have created
    const [isSave, setIsSave] = useState([]);                           // Stores a list of true/false to determine is tables should be delete or save
    const [isEdit, setIsEdit] = useState(false);                        // Stores the state of how data are being display (Edit / Display)
    const [isDelete, setDelete] = useState({});
    const [searchData, setSearchData] = useState("");                   // To store search value
    const [modifiedTableValue, setModifiedTableValue] = useState({});

    // Run on initial load
    useEffect(() => {
        IsLogin(navigate);                                              // Check for Login
        queryTableList();                                               // Retrieve a list of user created table
        window.scrollTo(0, 0);
    }, [navigate])

    useEffect(() => {
        setLoadState(!Object.values(loading).every(item => item === false))
    }, [loading])

    // Get greetings type
    function getGreetings() {
        let timeNow = new Date().getHours();
        let rtnTxt = "";
        if (timeNow > 5 && timeNow < 12)
            rtnTxt += "Good morning, ";
        else if (timeNow >= 12 && timeNow < 6)
            rtnTxt += "Good afternoon, ";
        else
            rtnTxt += "Good evening, ";
        return rtnTxt;
    }

    // Query API for table list to be stored in tableList
    function queryTableList() {
        setLoading({ ...loading, "QTL": true })
        fetch(configFile["api_url"] + "/userDataManager/listTable", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    else
                        setTableList(response);
                }
                setLoading({ ...loading, "QTL": false })
            })
            .catch(error => console.error(error));
    }

    // Query API to update a table name
    function queryUpdateTable(id) {
        setLoading({ ...loading, "QUT": true })
        fetch(configFile["api_url"] + "/userDataManager/updateTableName", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableID": id,
                // "tableName": document.getElementById(id).value.replaceAll("'", "\\'")
                "tableName": modifiedTableValue[id].replaceAll("'", "\\'")
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    // Query list again if there is no error
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"]);
                    else {
                        // Reset button type on success
                        setIsSave({ ...isSave, [id]: false });
                        queryTableList();
                    }
                }
                setLoading({ ...loading, "QUT": false })
            })
            .catch(error => console.error(error));
    }

    // Query API to insert a new table
    function queryAddingTable() {
        setLoading({ ...loading, "QAT": true })
        let name = document.getElementById("addDataGroup");
        fetch(configFile["api_url"] + "/userDataManager/addTable", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableName": name.value.replaceAll("'", "\\'"),
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    // Query list again if there is no error
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"])
                    else {
                        // Clear add value text field on success
                        name.value = "";
                        queryTableList()
                    }
                }
                setLoading({ ...loading, "QAT": false })
            })
            .catch(error => console.error(error));
    }

    // Query API to remove a table
    function queryRemoveTable(id) {
        setLoading({ ...loading, "QRT": true })
        fetch(configFile["api_url"] + "/userDataManager/removeTable", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_api_access_token')
            },
            body: JSON.stringify({
                "tableID": id,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response !== null) {
                    // Store list if there is no error
                    if ("detail" in response) {
                        console.log(response["Error"]);
                        if (response["detail"] === "Could not validate credentials") {
                            navigate("/user-login", { replace: true });
                            clearLocalStorage();
                        }
                    }
                    // Query list again if there is no error
                    else if (response["Error"] !== undefined)
                        console.log(response["Error"]);
                    else {
                        setTableList([]);
                        queryTableList();
                    }
                }
                setLoading({ ...loading, "QRT": false })
            })
            .catch(error => console.error(error));
    }

    return (
        <div className="HomePage">
            {loadState ? <Loadings /> : ""}
            <div className="HomePage-MainContainer">
                <div className="HomePage-TitleContainer">
                    <div className="HomePage-Greetings">{getGreetings()}</div>
                    <div className="HomePage-UserName">{localStorage.getItem("lh_api_display_name")}</div>
                </div>
                <div className="HomePage-TableListMainContainer">
                    <div className="HomePage-AccessibilityContainer">
                        <div className="HomePage-SearchBarContainer">
                            <input className="HomePage-SearchBar"
                                value={searchData}
                                id="searchInput"
                                onChange={e => {
                                    setSearchData(e.target.value);
                                }} />
                            <div className="HomePage-SearchIcon"><IoSearchOutline /></div>
                            <div className="HomePage-Search-CancelButton"
                                onClick={() => setSearchData("")}>
                                <IoCloseSharp />
                            </div>
                        </div>
                        <div className='HomePage-MainButtonContainer'>
                            <button className='HomePage-MainButton1' onClick={() => {
                                setIsEdit(!isEdit)
                                setModifiedTableValue({})
                                Object.keys(isSave).forEach((key) => {
                                    isSave[key] = false;
                                });
                            }}>
                                {isEdit ? <IoBanOutline /> : <IoPencilSharp />}
                            </button>
                        </div>
                    </div>
                    <div className='HomePage-TableListContainer'>
                        {
                            isEdit ?
                                <div key="addDataGrpContainer" className="HomePage-AddDataGrpContainer-IndividualTable">
                                    <div className="HomePage-AddDataGrpContainer1-IndividualTable">
                                        <div className="TablePage-DataPointNamelbl">New Table Name:</div>
                                        <button className={tableList.length >= 10 ? "HomePage-AddDataGrpBtn1Disable" : "HomePage-AddDataGrpBtn1"}
                                            onClick={() => {
                                                // Add new table if there is less than 10 tables
                                                if (tableList.length < 10 && document.getElementById("addDataGroup").value !== "") {
                                                    queryAddingTable();
                                                    document.getElementById("addDataGroup").value = "";
                                                }
                                            }}>
                                            <IoAddCircleOutline />
                                        </button>
                                    </div>
                                    <input id="addDataGroup" disabled={tableList.length >= 10} className="HomePage-EditDataGrpInput"
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                // Add new table if there is less than 10 tables
                                                if (tableList.length < 10 && e.target.value !== "") {
                                                    queryAddingTable();
                                                    e.target.value = "";
                                                }
                                            }
                                            else if (e.key === "Escape")
                                                // Close editing if escape is pressed
                                                setIsEdit(false)
                                            else if (e.key === "\"")
                                                e.preventDefault();
                                        }} maxLength={20} />
                                </div> : <></>
                        }
                        {
                            tableList.length === 0 ?
                                <label className="HomePage-DataGrpContainer-IndividualTable">You do not have any data group!</label> :
                                <div style={isEdit ? { display: "flex", flexDirection: "column", gap: "0.3rem" } : {}} >
                                    {
                                        // Display all tables
                                        tableList.map((tableListData, index) => {
                                            if (tableListData["name"].toUpperCase().includes(searchData.toUpperCase()) || searchData === "") {
                                                return (
                                                    isEdit ?
                                                        <div className="HomePage-DataGrpContainer" key={index} id={"tableData" + tableListData["id"]}>
                                                            <input className="HomePage-EditDataGrpInput" id={tableListData["id"]}
                                                                maxLength={20}
                                                                defaultValue={modifiedTableValue.hasOwnProperty(tableListData["id"]) ? modifiedTableValue[tableListData["id"]] : tableListData["name"]}
                                                                onChange={e => {
                                                                    // Change type to save from delete
                                                                    setModifiedTableValue({ ...modifiedTableValue, [tableListData["id"]]: e.target.value });
                                                                    setIsSave({ ...isSave, [tableListData["id"]]: true });
                                                                }}
                                                                onKeyDown={event => {
                                                                    if (event.key === "Enter") {
                                                                        if (isSave[tableListData["id"]])
                                                                            queryUpdateTable(tableListData["id"]);
                                                                    }
                                                                    else if (event.key === "\"")
                                                                        event.preventDefault();
                                                                }} />
                                                            <button
                                                                className={isSave[tableListData["id"]] ? "HomePage-EditDataGrpBtn" : "HomePage-EditDataGrpBtnDelete"}
                                                                onClick={() =>
                                                                    isSave[tableListData["id"]] ?
                                                                        queryUpdateTable(tableListData["id"]) :
                                                                        setDelete({ ...isDelete, [tableListData["id"]]: true })
                                                                }>
                                                                {isSave[tableListData["id"]] ? <IoDocumentTextOutline /> : <IoTrashOutline />}
                                                            </button>
                                                            {
                                                                isDelete[tableListData["id"]] && (
                                                                    <div className="DeletePrompt-MainContainer">
                                                                        <div className="DeletePrompt-Container">
                                                                            <p className="DeletePrompt-Text">Are you sure you want to delete table "{tableListData["name"]}"?</p>
                                                                            <div className="DeletePrompt-ButtonContainer">
                                                                                <button className="DeletePrompt-Button" onClick={() => {
                                                                                    queryRemoveTable(tableListData["id"]);
                                                                                }}>Yes</button>
                                                                                <button className="DeletePrompt-Button" onClick={() => {
                                                                                    setDelete({ ...isDelete, [tableListData["id"]]: false })
                                                                                }}>No</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div> :
                                                        <div className="HomePage-DataGrpContainer-IndividualTable" key={index}
                                                            id={tableListData["id"]}
                                                            onClick={
                                                                () => {
                                                                    let newURL = `${"/lhtables/"}?tableid=${tableListData["id"]}`;
                                                                    window.history.pushState("", "", "");       // Just to activate browser state
                                                                    navigate(newURL, { replace: true });
                                                                }}>
                                                            <label className="HomePage-IndividualTable-Name">
                                                                {tableListData["name"]}
                                                            </label>
                                                        </div>
                                                )
                                            }
                                            else return <></>;
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}